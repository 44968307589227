import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

interface BreadcrumbNavigationProps {
    breadcrumbs: { path: string; text: string }[];
}

const linkStyles: React.CSSProperties = {
    textDecoration: 'none',
    color: 'inherit',
    cursor: 'pointer',
};

const BreadcrumbNavigation: React.FC<BreadcrumbNavigationProps> = ({ breadcrumbs }) => {
    const len = breadcrumbs.length;
    return (
        <div style={{ marginLeft:'10px', marginTop: '5px', marginBottom: '5px', marginRight: '30px' }}>
            <Breadcrumbs aria-label="breadcrumb">
                {breadcrumbs.map((breadcrumb, index) => (
                    (index !== len-1) ? <Link style={linkStyles} 
                    onMouseEnter={(e) => { 
                        (e.target as HTMLElement).style.color = '#0C73EB'; 
                        (e.target as HTMLElement).style.textDecoration= 'underline'; 
                    }} 
                    onMouseLeave={(e) => { 
                        (e.target as HTMLElement).style.color = 'inherit'; 
                        (e.target as HTMLElement).style.textDecoration= 'none';
                    }} 
                    key={index} to={breadcrumb.path}>{breadcrumb.text}</Link> :
                    <Typography key={index} color="text.primary"><b>{breadcrumb.text}</b></Typography>
                ))}
            </Breadcrumbs>
        </div>
    );
};

export default BreadcrumbNavigation;
