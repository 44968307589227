import { Dot, Label, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import ExportGraph from "./ExportGraph";
import { useDispatch, useSelector } from "react-redux";
import { resetFilters, selectFilters, updateFilters } from "../store/slices/filtersSlice";

function CustomDot(props: any) {
    const { cy, cx, fill, payload, onX, curation } = props;
    const dispatch = useDispatch();
    const filters: any = {...useSelector(selectFilters)};
    const columnMap: any = {"GSE Accession IDs": "GEOAccession", "Cell Types": "GEOAccession", "Sequencing Platform": "InstrumentModel", "Library Processing Protocol": "LibraryProcessingProtocol", "Publication Year": "PublicationYear", "Cell Sorting Technique": "CellSortingTechnique", "Gender": "GEOAccession", "Age": "Age", "Cell Type": "CellType", "Contributor": "Contributor", "Disease": "Disease", "Library Preparation Platform": "LibraryPreparationPlatform", "Sample Acquisition Method": "SampleAcquisitionMethod", "Sequence Data Aligner": "SequenceDataAligner", "Sequence Data Processor": "SequenceDataProcessor", "Sequencer": "Sequencer", "Last Update Year": "LastUpdateYear", "Technology Type": "TechnologyType", "Disease Status": "DiseaseStatus", "Extracted Molecule": "ExtractedMolecule", "Library Selection": "LibrarySelection", "Library Source": "LibrarySource", "Library Strategy": "LibraryStrategy"};
    const handlePointClick = () => {
        Object.keys(filters).forEach(filter => filters[filter] = []);
        let filterToSelect = onX;
        if (onX === "Cell Types") {
            filterToSelect = "GSE Accession IDs"
        }
        if (onX === "Gender") {
            filters[columnMap[onX]].push(...payload["GSE List"]);
        }else {
            filters[columnMap[onX]].push(payload[filterToSelect]);
        }
        dispatch(updateFilters(filters));
        window.open(`/${curation}-table`,'_blank');
        setTimeout(() => { 
            dispatch(resetFilters());
        },2000);
    }
    return (
        <Dot r={8} cy={cy} cx={cx} fill={fill} cursor="pointer" onClick={handlePointClick}/>
    );
}

function LineGraph( { data, width, height, margin, onX, onY, curation } : any ) {
    return (
        <div id={onX+onY} style={{ paddingTop: '50px', paddingBottom: '50px' }}>
            <center style={{marginBottom: '0.5vh'}}>
                <span style={{fontSize: '0.97vw', color: 'black', fontWeight: 'bold'}}>
                    {onX} Vs {onY}<ExportGraph onX={onX} onY={onY} chart="line" data={data}/>
                </span>
            </center>
            <LineChart width={width*(data.length > 21 ? 0.96 : 0.48)} height={height} data={data} margin={margin} style={{ border: '1px solid #ccc', margin: '3px' }}>
                <XAxis minTickGap={-20} fontSize={data.length > 75 ? "0.535vw" : "0.785vw"} height={50} dataKey={onX} angle={-33} textAnchor="end">
                    <Label value={onX} offset={-margin.bottom+5} fontSize="0.862vw" fontWeight="bold" position="insideBottom"/>
                </XAxis>
                <YAxis allowDecimals={false} fontSize="0.785vw" label={{ value: onY, angle: -90, position: 'left', dy: -30, dx: -10, fontSize: '0.862vw', fontWeight: 'bold' }}/>
                <Tooltip/>
                <Line strokeWidth={1.5} type="monotone" dataKey={onY} stroke="#82ca9d" activeDot={ <CustomDot onX={onX} curation={curation}/> }/>
            </LineChart>
        </div>
    );
}

export default LineGraph;