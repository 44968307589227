import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../store/slices/userSlice";

function RequireAuth( { children }: any) {
    const loggedUser = useSelector(selectUser);
    if (!loggedUser.isAuthenticated) {
        return loggedUser.email ? <Navigate to="/change-password"/> : <Navigate to="/login"/>;
    }
    return children;
}

export default RequireAuth;