import React from 'react';
import './Header.css';
import logo from './companylogo.png';

const Header: React.FC = () => {
  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Company Logo" className="logo-image" />
      </div>
      <div className="header-title">
        <h1>Single-cell RNA Sequencing</h1>
        <h2>Data Curation Portal v1.0</h2>
      </div>
      <nav className="nav">
        <a href="#curation-request">Curation Request</a>
        <a href="#datasets">Datasets</a>
        <a href="#metadata-visualization">Metadata Visualization</a>
        <button className="Sign-button">Sign Up</button>
        <button className="demo-button">Book a Demo</button>
      </nav>
    </header>
  );
};

export default Header;
