import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import BreadcrumbNavigation from "../components/BreadcrumbNavigation";
import { Button, FormControlLabel, FormLabel, Paper, Radio, RadioGroup, Stack, TextField } from "@mui/material";
import isURL from "validator/lib/isURL";
import NavigationAppBar from "../components/NavigationAppBar";
import { IoMail } from "react-icons/io5";
import icon from "../companyicon.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../store/slices/userSlice";
import { axiosConfig, handleLogout, handleRefresh, refreshAccessToken, setAccessToken } from "../axiosConfig";

type FormValues = {
    diseaseCategory: string
    affiliation: string
    customizedCuration: string
    curationFile: FileList | undefined
    datasetSource: string
    url: string
}

function CurationRequest() {
    const form = useForm<FormValues>({
        defaultValues: {
            diseaseCategory: '', affiliation: '', customizedCuration: 'No', curationFile: undefined, datasetSource: "Publicly Available Datasets", url: '',
        }
    });
    const { register, handleSubmit, reset, formState } = form;
    const { errors } = formState;
    const [showCurationUpload, setShowCurationUpload] = useState(false);
    const [showDatasetUpload, setShowDatasetUpload] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loggedUser = useSelector(selectUser);
    setAccessToken(loggedUser.accessToken);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const onSubmit = async (data: FormValues) => {
        try {
            setDisableSubmit(true);
            const formData = new FormData();
            formData.append("disease_category", data.diseaseCategory);
            formData.append("affiliation", data.affiliation);
            formData.append("customized_curation", data.customizedCuration === "Yes" ? 'true' : 'false');
            if (data.customizedCuration === "Yes" && data.curationFile !== undefined) {
                formData.append("curation_file", data.curationFile[0]);
            }
            formData.append("source_of_dataset",data.datasetSource === "Publicly Available Datasets" ? 'false' : 'true');
            if (data.datasetSource === "Publicly Available Datasets") {
                formData.append("enter_url", data.url);
            }
            const response = await axiosConfig.post("/api/order_table_write", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            if (response.data.status === "SUCCESS") {
                setDisableSubmit(false);
                navigate('/curation-requests');
                reset();
            }
        }catch(error: any) {
            try {
                if (error.response.data.msg === "Token has been revoked") {
                    handleLogout(dispatch, navigate);
                }
                if (error.response.data.msg === "Token has expired") {
                    setDisableSubmit(false);
                    const response = await handleRefresh(loggedUser.refreshToken);
                    refreshAccessToken(dispatch, loggedUser, response);
                    document.getElementById("submit")?.click();
                }
            }catch(error: any) {
                if (error.response.data.msg === "Token has expired") {
                    handleLogout(dispatch, navigate);
                }
            }
        }
    };
    return (
        <Fragment>
            <div><NavigationAppBar/></div>
            <BreadcrumbNavigation breadcrumbs={[{path:'/', text:'Home'},{path:'/create-curation-request', text:'Create Curation Request' },]}/>
            <div className="table" style={{ overflow: 'auto', marginTop: '-7px', marginLeft: '-8px', marginRight: '-8px', marginBottom: '10px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '85vh' }}>
                    <Paper elevation={12} style={{ borderRadius:'10px' }}>
                        <div style={{ textAlign: 'center', padding: '20px', border: '1px solid #ccc', borderRadius: '10px', paddingBottom: '35px' }}>
                            <Stack direction="row" style={{justifyContent:'center'}}>
                                <img style={{ width: '51.2px', height: '51.2px', paddingTop: '10px', marginLeft: '-15px' }} src={icon} alt="company logo"/>
                                <p style={{ fontSize: '24px' }}>Create Curation Request</p>
                            </Stack>
                            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                                <Stack spacing={2.25} width={450}>
                                    <TextField autoComplete="off" label="Disease Category" type="text" 
                                        {...register("diseaseCategory", {
                                            required: {
                                                value: true,
                                                message: 'Enter disease category',
                                            },
                                        })} 
                                        error={!!errors.diseaseCategory} 
                                        helperText={errors.diseaseCategory?.message}/>
                                    <TextField autoComplete="off" label="Affiliation" type="text" 
                                        {...register("affiliation", {
                                            required: {
                                                value: true,
                                                message: 'Enter an affiliation',
                                            },
                                        })}
                                        error={!!errors.affiliation}
                                        helperText={errors.affiliation?.message}/>
                                    <Stack direction="row" style={{border:'1.75px solid #ccc', paddingTop: '10px', borderRadius: '4px'}}>
                                        <FormLabel style={{ marginLeft: '25px', color: 'black', fontSize: '17px' }}>Customized Curation</FormLabel>
                                        <RadioGroup defaultValue="No" row aria-labelledby="radioId1" style={{marginLeft: '40px', marginTop: '-9px'}} name="customizedCuration" onChange={(event) => { setShowCurationUpload(event.target.value === 'Yes'); }}>
                                            <FormControlLabel value="Yes" control={<Radio/>} label="Yes" {...register("customizedCuration")}/>
                                            <FormControlLabel value="No" control={<Radio/>} label="No" {...register("customizedCuration")}/>
                                        </RadioGroup>
                                    </Stack>
                                    {showCurationUpload && <Stack direction="row" style={{display:'flex',alignItems:'center'}}>
                                            <FormLabel style={{marginLeft:'25px', color:'black', fontSize:'17px'}}>Customized Metadata Fields</FormLabel>
                                            <input id="curationFile" type="file" style={{marginLeft: '12px'}} {...register("curationFile", {
                                                validate : (curationFile) => {
                                                    const formats = [".txt",".csv",".xls",".xlsx",".TXT",".CSV",".XLS",".XLSX"];
                                                    let format = "-1", msg ;
                                                    if ( curationFile !== undefined &&  curationFile.length > 0 ) {
                                                        const index = curationFile[0].name.lastIndexOf('.');
                                                        const fileInput = document.getElementById('curationFile') as HTMLInputElement | null;
                                                        format = index !== -1 ? curationFile[0].name.slice(index) : "-1";
                                                        if (!formats.includes(format)) {
                                                            if (fileInput) { fileInput.value = ""; }
                                                            msg = "Valid file formats (.txt, .csv, .xls or .xlsx)";
                                                        } else { //5242880
                                                            if (curationFile[0].size > 5242880) { 
                                                                if (fileInput) { fileInput.value = ""; }
                                                                msg = "File size should be less than 5MB"; 
                                                            }
                                                        }
                                                    } else { msg = "Upload a file for custom curation"; }
                                                    return msg;
                                                },
                                            })}/>
                                            {errors.curationFile && <p style={{color: '#ef5350'}}>{errors.curationFile?.message}</p>}
                                    </Stack>}
                                    <Stack direction="row" style={{border:'1.75px solid #ccc', paddingTop: '10px', borderRadius: '4px'}}>
                                        <FormLabel style={{ marginLeft: '25px', color: 'black', fontSize: '17px', whiteSpace: 'pre', marginTop: '10px' }}>Source of Dataset</FormLabel>
                                        <RadioGroup defaultValue="Publicly Available Datasets" row aria-labelledby="radioId2" style={{marginLeft: '34px', marginTop: '-9px'}} name="datasetSource" onChange={(event) => { setShowDatasetUpload(event.target.value === 'In-house Datasets'); }}>
                                            <FormControlLabel value="Publicly Available Datasets" control={<Radio/>} label="Publicly Available Datasets" {...register("datasetSource")}/>
                                            <FormControlLabel style={{marginTop: '-14px'}} value="In-house Datasets" control={<Radio/>} label="In-house Datasets" {...register("datasetSource")}/>
                                        </RadioGroup>
                                    </Stack>
                                    {showDatasetUpload ? (<Button variant="contained" style={{ height: 54, color: 'black', textTransform: 'none', fontSize: '20px', fontWeight: 'bold', whiteSpace: 'pre', width: '450px', backgroundColor: '#F1F3F6', border: '1px solid #ccc' }} href="mailto:bioinformatics@strandls.com">
                                            <IoMail style={{marginRight:'10px',marginTop:'-3px' }} size={23}/>Contact Us</Button>) : 
                                        (<TextField autoComplete="off" label="Accession URL" type="text"
                                        {...register("url", {
                                            required: {
                                                value: true,
                                                message: 'Enter an URL for source of dataset',
                                            },
                                            validate: (url) => {
                                                return isURL(url, { require_protocol: true }) || "Enter a valid URL";
                                            },
                                        })} 
                                        error={!!errors.url} 
                                        helperText={errors.url?.message}/>)}
                                    {!showDatasetUpload && <Button disabled={disableSubmit} id="submit" type="submit" variant="contained" style={{ height: 54, backgroundColor: '#0C73EB', textTransform: 'none', fontSize: '23px', fontWeight: 'bold', color: 'white'}}>Submit</Button>}
                                </Stack>
                            </form>
                        </div>
                    </Paper>
                </div>
            </div>
        </Fragment>
    );
}

export default CurationRequest;