import { Cell, Legend, Pie, PieChart, Tooltip, LegendProps } from "recharts";
import ExportGraph from "./ExportGraph";
import { useDispatch, useSelector } from "react-redux";
import { resetFilters, selectFilters, updateFilters } from "../store/slices/filtersSlice";

interface PieGraphProps {
    data: { name: string; [key: string]: any }[];
    width: number;
    height: number;
    radius: string | number;
    onX: string;
    onY: string;
    curation: string;
}

const columnMap: Record<string, string> = {"Sequencing Platform": "InstrumentModel","Library Processing Protocol": "LibraryProcessingProtocol","Publication Year": "PublicationYear","Cell Sorting Technique": "CellSortingTechnique","Gender": "GEOAccession","Age": "Age","Contributor": "Contributor","Disease": "Disease","Library Preparation Platform": "LibraryPreparationPlatform","Sample Acquisition Method": "SampleAcquisitionMethod","Sequence Data Aligner": "SequenceDataAligner", "Sequence Data Processor": "SequenceDataProcessor","Sequencer": "Sequencer","Last Update Year": "LastUpdateYear", "Technology Type": "TechnologyType","Disease Status": "DiseaseStatus","Extracted Molecule": "ExtractedMolecule", "Library Selection": "LibrarySelection", "Library Source": "LibrarySource","Library Strategy": "LibraryStrategy"};

const generateColors = (length: number) => {
    const baseColors = [
        'lightsteelblue', 'darkseagreen', 'cadetblue', 'mediumaquamarine', 'lightseagreen','lightgreen', 'aquamarine', 'powderblue', 'lightskyblue', 'cornflowerblue','sandybrown', 'lightsalmon', 'plum', 'palegoldenrod', 'silver', 'palegreen','palevioletred', 'gainsboro', 'lightslategray', 'aliceblue', 'antiquewhite','burlywood', 'darkgoldenrod', 'chocolate', 'coral', 'darkturquoise'
    ];


    return Array.from({ length }, (_, i) => baseColors[i % baseColors.length]);
};


function PieGraph({ data, width, height, radius, onX, onY, curation }: PieGraphProps) {
    const dispatch = useDispatch();
    const filters = useSelector(selectFilters);

    const handlePieClick = (pieData: any) => {
        const updatedFilters = { ...filters };
        Object.keys(updatedFilters).forEach(filter => updatedFilters[filter] = []);
        
        if (onX === "Gender") {
            updatedFilters[columnMap[onX]].push(...pieData["GSE List"]);
        } else {
            updatedFilters[columnMap[onX]].push(pieData.name);
        }
        
        dispatch(updateFilters(updatedFilters));
        window.open(`/${curation}-table`, '_blank');
        
        setTimeout(() => {
            dispatch(resetFilters());
        }, 2000);
    };

    const customColor = (value: string) => (
        <span style={{ color: '#666', fontSize: '0.754vw' }}>{value}</span>
    );

    const itemsPerColumn = 15;
    const numColumns = Math.ceil(data.length / itemsPerColumn);

    const renderLegendContent = (props: LegendProps) => {
        const { payload } = props;

        if (!payload) {
            return null;
        }
        const columns: JSX.Element[][] = Array.from({ length: numColumns }, () => []);

        payload.forEach((entry, index) => {
            columns[index % numColumns].push(
                <div key={entry.value} style={{ width: '100%', boxSizing: 'border-box', marginBottom: '4px', display: 'flex', alignItems: 'flex-start' }}>
                <span style={{ color: entry.color, marginRight: '8px', flexShrink: 0 }}>●</span>
                <div style={{ flex: 1, overflowWrap: 'break-word', wordBreak: 'break-word' }}>
                    {entry.value}
                </div>
            </div>
            );
        });

        return (
            <div  style={{ display: 'flex',width:'100%', flexWrap: 'wrap' }}>
                {columns.map((column, index) => (
                    <div key={index} style={{ flex: `1 0 ${100 / numColumns}%` }}>
                        {column}
                    </div>
                ))}
            </div>
        );
    };

    const colors = generateColors(data.length);
    return (
        <div id={`${onX}${onY}`} style={{ paddingTop: '50px', paddingBottom: '50px' }}>
            <center style={{ marginBottom: '0.5vh' }}>
                <span style={{ fontSize: '0.97vw', color: 'black', fontWeight: 'bold' }}>
                    {onX} Vs {onY}
                    <ExportGraph onX={onX} onY={onY} chart="pie" data={data} />
                </span>
            </center>
            <PieChart  width={width * 0.48 }  height={height * (0.50)}  margin={{ left: -0.2164 * window.innerWidth }}  style={{ border: '1px solid #ccc', margin: '3px' }} >
                <Pie dataKey={onY}data={data}cx="50%"cy="50%"labelLine={false}outerRadius={radius} onClick={handlePieClick} cursor="pointer">
                     {colors.map((entry: any, index: any) => (
                        <Cell key={index} fill={entry}/>
                    ))}
                </Pie>
                <Legend layout="vertical" verticalAlign="middle"wrapperStyle={{ left: 0.260 * window.innerWidth, lineHeight: '2.05vh' }}formatter={customColor}content={renderLegendContent as any}/>
                <Tooltip />
            </PieChart>
        </div>
    );
}

export default PieGraph;
