import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, persistor } from './store/index';
import { PersistGate } from 'redux-persist/integration/react';

const ele = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(ele);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>    
          <App/>
      </PersistGate>
    </Provider>
  </BrowserRouter>
);