import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, Divider, IconButton, ListItemText, Menu, MenuItem } from '@mui/material';
import { GoChevronDown } from 'react-icons/go';
import logo from "../companylogo.png";
import { BsDatabaseFill, BsGraphUpArrow } from 'react-icons/bs';
import { AiFillDatabase } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../store/slices/userSlice';
import { axiosConfig, handleLogout, handleRefresh, refreshAccessToken, setAccessToken } from '../axiosConfig';
import { NestedMenuItem } from 'mui-nested-menu';
import { updateDiseaseCategory } from '../store/slices/diseaseCategorySlice';
import { resetFilters } from '../store/slices/filtersSlice';
import SearchBar from './SearchBar';

const linkStyles: React.CSSProperties = { textDecoration: 'none', color: 'black', cursor: 'pointer' };
const typographyStyles: React.CSSProperties = { marginRight: '35px', fontSize: '12pt', cursor: 'pointer' };
const appBarStyles: React.CSSProperties = { backgroundColor: '#F1F3F6',//e1e6ec
    height: '60px', width: '100%', border: '1px solid #ccc'};

function CurationRequest() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    return (
        <React.Fragment>
            <Typography style={{color: 'black'}} variant="h6" component="div" sx={typographyStyles} onClick={(event) => { setAnchorEl(event?.currentTarget); }}>
                {open ? <b style={{color: '#0C73EB', whiteSpace: 'pre' }}><AiFillDatabase size={18} style={{ marginRight: '5px', marginBottom: '-3px' }}/>Curation Request<GoChevronDown style={{ marginLeft: '3px', marginBottom: '-3px' }}/></b> : 
                    <b style={{ whiteSpace: 'pre' }} className='navbar'><AiFillDatabase size={18} style={{ marginRight: '5px', marginBottom: '-3px' }}/>Curation Request<GoChevronDown style={{ marginLeft: '3px', marginBottom: '-3px' }}/></b>}
            </Typography>
            <Menu id="curationmenu" style={{marginTop: '8px'}} anchorEl={anchorEl} open={open} onClose={() => { setAnchorEl(null); }}>
                <Link to={"/create-curation-request"} style={linkStyles}>
                    <MenuItem style={{paddingLeft:'20px', paddingBottom: '10px', paddingTop: '10px', paddingRight: '50px'}} onClick={() => { setAnchorEl(null); }}>Create Curation Request</MenuItem>
                </Link>
                <Link to={"/curation-requests"} style={linkStyles}>
                    <MenuItem style={{paddingLeft: '20px', paddingBottom: '10px', paddingTop: '10px'}} onClick={() => { setAnchorEl(null); }}>My Curation Requests</MenuItem>
                </Link>
            </Menu>
        </React.Fragment>
    );
}

function Datasets() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    const handleDiseaseCategoryChange = (selectedDiseaseCategory: string) => {
        dispatch(resetFilters());
        dispatch(updateDiseaseCategory(selectedDiseaseCategory));
    };
    return (
        <React.Fragment>
            <Typography style={{color: 'black'}} variant="h6" component="div" sx={typographyStyles} onClick={(event) => { setAnchorEl(event?.currentTarget); }}>
                {open ? <b style={{color: '#0C73EB', whiteSpace: 'pre' }}><BsDatabaseFill size={18} style={{ marginRight: '6px', marginBottom: '-3px' }}/>Datasets<GoChevronDown style={{ marginLeft: '3px', marginBottom: '-3px' }}/></b> : 
                    <b style={{ whiteSpace: 'pre' }} className='navbar'><BsDatabaseFill size={18} style={{ marginRight: '6px', marginBottom: '-3px' }}/>Datasets<GoChevronDown style={{ marginLeft: '3px', marginBottom: '-3px' }}/></b>}
            </Typography>
            <Menu id="datasetmenu" style={{marginTop: '8px'}} anchorEl={anchorEl} open={open} onClose={() => { setAnchorEl(null); }}>
                <Link to={"/publicly-curated-datasets"} style={linkStyles}>
                    <MenuItem style={{paddingLeft:'20px', paddingBottom: '10px', paddingTop: '10px', paddingRight: '30px'}} onClick={() => { setAnchorEl(null); }}>Curated Datasets from Public Resources</MenuItem>
                </Link>
                <NestedMenuItem label="Study Level Curation" parentMenuOpen={open} style={{paddingLeft: '12px', paddingBottom: '10px', paddingTop: '10px'}}>
                    <Link to={"/study-table"} style={linkStyles}>
                        <MenuItem style={{paddingLeft: '20px', paddingBottom: '10px', paddingTop: '10px', paddingRight: '30px'}} onClick={() => { setAnchorEl(null); handleDiseaseCategoryChange("Inflammatory Bowel Disease"); }}>Inflammatory Bowel Disease</MenuItem>
                    </Link>
                    <Link to={"/study-table"} style={linkStyles}>
                        <MenuItem style={{paddingLeft: '20px', paddingBottom: '10px', paddingTop: '10px'}} onClick={() => { setAnchorEl(null); handleDiseaseCategoryChange("Neurodegenerative Disease"); }}>Neurodegenerative Disease</MenuItem>
                    </Link>
                </NestedMenuItem>
                <NestedMenuItem label="Experiment Level Curation" parentMenuOpen={open} style={{paddingLeft: '12px', paddingBottom: '10px', paddingTop: '10px'}}>
                    <Link to={"/experiment-table"} style={linkStyles}>
                        <MenuItem style={{paddingLeft: '20px', paddingBottom: '10px', paddingTop: '10px', paddingRight: '30px'}} onClick={() => { setAnchorEl(null); handleDiseaseCategoryChange("Inflammatory Bowel Disease"); }}>Inflammatory Bowel Disease</MenuItem>
                    </Link>
                    <Link to={"/experiment-table"} style={linkStyles}>
                        <MenuItem style={{paddingLeft: '20px', paddingBottom: '10px', paddingTop: '10px'}} onClick={() => { setAnchorEl(null); handleDiseaseCategoryChange("Neurodegenerative Disease"); }}>Neurodegenerative Disease</MenuItem>
                    </Link>
                </NestedMenuItem>
                <NestedMenuItem label="Sample Level Curation" parentMenuOpen={open} style={{paddingLeft: '12px', paddingBottom: '10px', paddingTop: '10px'}}>
                    <Link to={"/sample-table"} style={linkStyles}>
                        <MenuItem style={{paddingLeft: '20px', paddingBottom: '10px', paddingTop: '10px', paddingRight: '30px'}} onClick={() => { setAnchorEl(null); handleDiseaseCategoryChange("Inflammatory Bowel Disease"); }}>Inflammatory Bowel Disease</MenuItem>
                    </Link>
                    <Link to={"/sample-table"} style={linkStyles}>
                        <MenuItem style={{paddingLeft: '20px', paddingBottom: '10px', paddingTop: '10px'}} onClick={() => { setAnchorEl(null); handleDiseaseCategoryChange("Neurodegenerative Disease"); }}>Neurodegenerative Disease</MenuItem>
                    </Link>
                </NestedMenuItem>
                <Link to={"/documentation"} style={linkStyles}>
                    <MenuItem style={{paddingLeft: '20px', paddingBottom: '10px', paddingTop: '10px'}} onClick={() => { setAnchorEl(null); }}>Documentation</MenuItem>
                </Link>
            </Menu>
        </React.Fragment>
    );
}

function LogoutMenu() {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loggedUser = useSelector(selectUser);
    setAccessToken(loggedUser.accessToken);
    const handleLogoutButton = async () => {
        try {
            const response = await axiosConfig.post('/api/logout');
            if (response.data.status === "SUCCESS") {
                handleLogout(dispatch,navigate);
            }
        }catch(error: any) {
            try{
                if (error.response.data.msg === "Token has been revoked") {
                    handleLogout(dispatch, navigate);
                }
                if (error.response.data.msg === "Token has expired") {
                    const response = await handleRefresh(loggedUser.refreshToken);
                    refreshAccessToken(dispatch, loggedUser, response);
                    if((await axiosConfig.post('/api/logout')).data.status === "SUCCESS") {
                        handleLogout(dispatch, navigate);
                    }
                }
            }catch(error: any) {
                if (error.response.data.msg === "Token has expired") {
                    handleLogout(dispatch,navigate);
                }
            }
        }
    }
    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <IconButton onClick={handleClick} size="small" sx={{ ml: 2, mb: 0.5 }} aria-controls={open ? 'account-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}>
                    <Avatar sx={{width: 48, height: 48, fontSize: '30px', fontWeight: 'bold'}}>{String(loggedUser.username)[0].toUpperCase()}</Avatar>
                </IconButton>
            </Box>
            <Menu anchorEl={anchorEl} id="logoutmenu" open={open} onClose={(handleClose)} onClick={handleClose} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                <MenuItem style={{paddingRight: '50px'}} onClick={handleClose}>
                    <ListItemText primary={loggedUser.username} secondary={loggedUser.email}/>
                </MenuItem>
                <Divider/>
                <MenuItem onClick={() => { navigate('/user-profile'); }}>
                    <Link key="profile" to={'/user-profile'} style={linkStyles}>Profile</Link>
                </MenuItem>
                <MenuItem onClick={handleLogoutButton}>
                    <Link key="Logout" to={'/login'} style={linkStyles}>Logout</Link>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}

function NavigationAppBar({ includeSearch = false }: any) {
    const metadataVisualization = (
        <Typography variant="h6" component="div" sx={typographyStyles}>
            <Link to="/metadata-visualization" style={linkStyles}>
                <b style={{ whiteSpace: 'pre' }} className='navbar'><BsGraphUpArrow size={18} style={{ marginRight: '10px', marginBottom: '-3px' }}/>Metadata Visualization</b>
            </Link>
        </Typography>
    );
    const projectTitle = (
        <Typography variant="h6" component="div" style={{marginRight:'80px'}}>
            <span style={{fontSize: '15.5px',color:'black', whiteSpace: 'pre' }}><b>Single-cell RNA sequencing</b></span><br/>
            <center>
                <div style={{fontSize: '15px', color: 'gray', marginTop: '-6.5px', marginBottom: '10px'}}>
                    <b>Data curation portal v1.0</b>
                </div>
            </center>
        </Typography>
    );
    const navigate = useNavigate();
    return (
        <Box sx={{ flexGrow: 1 }} style={{ marginLeft: -25, marginRight: -25, marginTop: -12,} as React.CSSProperties}>
            <AppBar elevation={0} style={appBarStyles} position="static">
                <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img onClick={() => navigate('/')} style={{ width: '230px', height: '53.3px', marginRight:'40px', marginBottom: '5.5px', cursor: 'pointer'}} src={logo} alt="company logo"/>
                        {projectTitle}
                        <CurationRequest/>
                        <Datasets/>
                        {metadataVisualization}
                    </div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {includeSearch ? <SearchBar/>: <></>}
                        <LogoutMenu/>
                    </div>
                </Toolbar>
            </AppBar>
        </Box>
    );
}

export default NavigationAppBar;
