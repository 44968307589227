import { Alert, Button, Paper, Stack, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import  logo from "../companylogo.png";
import { axiosConfig } from "../axiosConfig";
import { useEffect, useState } from "react";
import { TbReload } from "react-icons/tb";
import { loadCaptchaEnginge, validateCaptcha, LoadCanvasTemplateNoReload } from "react-simple-captcha";
import { PhoneNumberUtil } from 'google-libphonenumber';
import { CustomPhone } from "../components/CustomPhone";

type FormValues = {
    fullName: string
    personalEmailId: string
    workEmailId: string
    phoneNumber: string
    work: string
    designation: string
    about: string
    captcha: string
}
const phoneUtil = PhoneNumberUtil.getInstance();
export const isPhoneValid = (phone: string) => {
    try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    }catch(error: any) {
        return false;
    }
};
function SignupForm() {
    useEffect(() => {
        loadCaptchaEnginge(6);
    },[]);
    const form = useForm<FormValues>({
        defaultValues: {
            fullName: '',
            personalEmailId: '',
            workEmailId: '',
            phoneNumber: '',
            work: '',
            designation: '',
            about: '',
            captcha: '',
        }
    });
    const [muiPhone, setMuiPhone] = useState("+91");
    const { register, handleSubmit, reset, formState, setError } = form;
    const { errors } = formState;
    const navigate = useNavigate();
    const [feedback, setFeedback] = useState<number | null>(null);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const onSubmit = async (data: FormValues) => {
        try {
            setDisableSubmit(true);
            const response = await axiosConfig.post('/api/signup', {
                    "name": data.fullName,
                    "personal_email": data.personalEmailId,
                    "work_email": data.workEmailId,
                    "phone": data.phoneNumber,
                    "academic_affiliation": data.work,
                    "designation": data.designation,
                    "purpose": data.about,
                    "strand_restriction": process.env.REACT_APP_STRAND_RESTRICTION,
                }
            );
            if (response.data.status === "SUCCESS") {
                setDisableSubmit(false);
                setFeedback(1);
                setTimeout(() => { 
                    navigate('/signup-success', { state: 'signup' } );
                    reset();
                }, 0);
            }
        }catch(error: any){
            if (error.response.data.data === "Email already exists") {
                setDisableSubmit(false);
                setError("personalEmailId", { type: "required", message: "Email already exists" });
                setError("workEmailId", { type: "required", message: "Email already exists" });
                setFeedback(2);
            }
        }
    };
    return (
        <div className="table" style={{ overflow: 'auto', marginTop: '-7px', marginLeft: '-8px', marginRight: '-8px', marginBottom: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundColor: '#F1F3F6', margin: '0px' }}>
                <Paper elevation={12} style={{ borderRadius:'10px' }}>
                    <div style={{ textAlign: 'center', backgroundColor: 'white', padding: '20px', paddingTop: '18px', paddingBottom: '18px', border: '1px solid #ccc', borderRadius: '10px' }}>
                        <img style={{ width: '240px', height: '49.19px', marginBottom: '-10px' }} src={logo} alt="company logo"/>
                        <p style={{ fontSize: '18px', marginTop: '20px', marginBottom: '20px' }}>Sign up for scRNA data curation portal v1.0</p>
                        <form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <Stack spacing={1.50} width={360}>
                                <TextField size="small" autoComplete="off" label="Full Name" type="text" 
                                    {...register("fullName", {
                                        required: {
                                            value: true,
                                            message: "Enter your full name",
                                        },
                                        validate: (fullName) => {
                                            return fullName.length >= 5 || "Full Name must be at least 5 characters long" ; 
                                        },
                                    })}
                                    error={!!errors.fullName}
                                    helperText={errors.fullName?.message}/>
                                <TextField size="small" autoComplete="off" label="Personal Email ID" type="email" 
                                    {...register("personalEmailId", {
                                        required: {
                                            value: true,
                                            message: "Enter personal email",
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                            message: "Enter a valid email",
                                        }
                                    })}
                                    error={!!errors.personalEmailId}
                                    helperText={errors.personalEmailId?.message}/>
                                <TextField size="small" autoComplete="off" label="Work Email ID" type="email" 
                                    {...register("workEmailId", {
                                        required: {
                                            value: true,
                                            message: "Enter work email",
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                            message: "Enter a valid email",
                                        },
                                        validate: (workEmailId) => {
                                            let domain = workEmailId.split('@')[1].toLowerCase();
                                            if (domain === "gmail.com" || domain === "yahoo.com" || domain === "hotmail.com" || domain === "outlook.com") {
                                                return "Enter a valid work email";
                                            }
                                            if (process.env.REACT_APP_STRAND_RESTRICTION === "Y" && domain !== "strandls.com") {
                                                return "Only @strandls domain allowed";
                                            }
                                        },
                                    })}
                                    error={!!errors.workEmailId}
                                    helperText={errors.workEmailId?.message}/>
                                <Controller name="phoneNumber" control={form.control} defaultValue="" 
                                    render={({ field }) => ( <CustomPhone value={muiPhone} onChange={(value) => { setMuiPhone(value); field.onChange(value); }}
                                    helperText={errors.phoneNumber?.message} error={!!errors.phoneNumber}/> )}
                                    rules={{ required: 'Enter phone number',
                                        validate: (muiPhone) => {
                                            return isPhoneValid(muiPhone) || "Enter a valid phone number";
                                        },
                                }}/>
                                <TextField size="small" autoComplete="off" label="Work/Academic Affiliation" type="text" 
                                    {...register("work", {
                                        required: {
                                            value: true,
                                            message: "Enter work/academic affiliation",
                                        },
                                        validate: (work) => {
                                            return work.length >= 5 || "Academic Affiliation must be at least 5 characters long" ; 
                                        },
                                    })}
                                    error={!!errors.work}
                                    helperText={errors.work?.message}/>
                                <TextField size="small" autoComplete="off" label="Designation" type="text" 
                                    {...register("designation", {
                                        required: {
                                            value: true,
                                            message: "Enter your designation",
                                        },
                                        validate: (designation) => {
                                            return designation.length >= 5 || "Designation must be at least 5 characters long" ; 
                                        },
                                    })}
                                    error={!!errors.designation}
                                    helperText={errors.designation?.message}/>
                                <TextField multiline rows={3} autoComplete="off" label="What brings you to this portal?" type="text" 
                                    {...register("about", {
                                        required: {
                                            value: true,
                                            message: "This is a required field",
                                        }
                                    })}
                                    error={!!errors.about}
                                    helperText={errors.about?.message}/>
                                <div style={{display:'flex'}}>
                                    <LoadCanvasTemplateNoReload/>
                                    <div style={{cursor:'pointer'}} onClick={() => loadCaptchaEnginge(6)}><TbReload/></div>
                                </div>
                                <TextField inputProps={{maxLength: 6}} size="small" autoComplete="off" label="Type the text" type="text" 
                                    {...register("captcha", {
                                        required: {
                                            value: true,
                                            message: "Enter the captcha text",
                                        },
                                        validate: (userCaptcha) => {
                                            return validateCaptcha(userCaptcha, false) || "Captcha does not match" ; 
                                        },
                                    })}
                                    error={!!errors.captcha}
                                    helperText={errors.captcha?.message}/>
                                {feedback && <Alert severity={ feedback === 1 ? "success": "error"}>{feedback === 1 ? "Sign up successful": "Email already exists"}</Alert>}
                                <Button disabled={disableSubmit} type="submit" variant="contained" style={{ height: 40, backgroundColor: '#0C73EB', textTransform: 'none', fontSize: '19px', color: 'white' }}><b>Sign up</b></Button>
                            </Stack>
                        </form>
                        <p style={{fontSize: '16px'}}>Already have an account? <span style={{ color: '#0C73EB', cursor: 'pointer', fontWeight: 'bold' }} 
                            onMouseEnter={(e) => {(e.target as HTMLElement).style.textDecoration = 'underline'}}
                            onMouseLeave={(e) => {(e.target as HTMLElement).style.textDecoration = 'none'}} onClick={() => { navigate('/login'); }}>Log in</span></p>
                    </div>
                </Paper>
            </div>
        </div>
    );
}

export default SignupForm;