import { Button, Stack } from "@mui/material";
import logo from "../companylogo.png";
import { useNavigate } from "react-router-dom";

function PageNotFound() {
    const navigate = useNavigate();
    return (
        <div className="table" style={{ backgroundColor: '#F1F3F6', overflow: 'auto', marginTop: '-7px', marginLeft: '-8px', marginRight: '-8px', marginBottom: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '45vh' }}>
                <div style={{ textAlign: 'left' }}>
                    <Stack spacing={3}>
                        <img style={{ width: '200px', height: '41px', marginLeft: '-10px'}} src={logo} alt="company logo"/>
                        <p style={{ fontSize: '16px' }}>Oops! <b>404</b> Page not found error</p>
                        <p style={{ fontSize: '16px' }}>The requested URL was not found in this server</p>
                        <Button size="small" variant="contained" style={{ height: 36, width: 180, backgroundColor: '#0C73EB', textTransform: 'none', fontSize: '16px', color: 'white' }} 
                            onClick={() => { navigate('/login'); }}>Back to Home</Button>
                    </Stack>
                </div>
            </div>
        </div>
    );
}

export default PageNotFound;