import { createSlice } from "@reduxjs/toolkit";

interface SearchState {
    searchKey : string
}

const initialState: SearchState = {
    searchKey: "",
};

const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        setSearchKey: (state, action) => {
            state.searchKey = action.payload;
        }
    }
});

export const { setSearchKey } = searchSlice.actions;
export const selectSearchKey = (state: { search: SearchState }) => state.search.searchKey;
export default searchSlice.reducer;