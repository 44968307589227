import { createSlice } from "@reduxjs/toolkit";

interface DiseaseState {
    diseaseCategory : string
    diseaseTypeList: string[]
    diseaseType: string | null
}

const initialState: DiseaseState = {
    diseaseCategory: "Inflammatory Bowel Disease",
    diseaseTypeList: [],
    diseaseType: null,
};

const diseaseSlice = createSlice({
    name: "disease",
    initialState,
    reducers: {
        updateDiseaseCategory: (state, action) => {
            state.diseaseCategory = action.payload;
        },
        updateDiseaseType: (state, action) => {
            state.diseaseType = action.payload;
        },
        updateDiseaseTypeList: (state, action) => {
            state.diseaseTypeList = action.payload;
        },
        resetDiseaseParams: (state) => {
            state.diseaseCategory = "Inflammatory Bowel Disease";
            state.diseaseTypeList = [];
            state.diseaseType = null;
        }
    }
});

export const { updateDiseaseCategory, updateDiseaseType, updateDiseaseTypeList, resetDiseaseParams } = diseaseSlice.actions;
export const selectDiseaseCategory = (state: { disease: DiseaseState }) => state.disease.diseaseCategory;
export const selectDiseaseType = (state: { disease: DiseaseState }) => state.disease.diseaseType;
export const selectDiseaseTypeList = (state: { disease: DiseaseState }) => state.disease.diseaseTypeList;
export default diseaseSlice.reducer;