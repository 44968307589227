import React from 'react';
import Header from '../components/LandingPage/Header';
import Features from '../components/LandingPage/Features';
import Footer from '../components/LandingPage/Footer';
import ImageCarousel from '../components/LandingPage/ImageCarousel';

const LandingPage: React.FC = () => {
  return (
    <div 
      className="landing-page"
      style={{
        minHeight: '100vh',       
        display: 'flex',           
        flexDirection: 'column',  
        overflowY: 'auto',         
        textAlign: 'center',       
      }}
    >
      <Header />
      <div 
        className="image-carousel-spacing"
        style={{ marginTop: '5rem' }}  
      >
        <ImageCarousel />
      </div>
      <div 
        className="features"
        style={{ 
          padding: '50px',        
          minHeight: '500px'     
        }}
      >
        <Features />
      </div>
      <div 
        className="footer"
        style={{
          marginTop: 'auto',     
          padding: '1rem',        
          backgroundColor: '#f1f1f1', 
          textAlign: 'center',    
          width: '100%',          
        }}
      >
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
