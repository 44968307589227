import { configureStore, combineReducers } from "@reduxjs/toolkit";
import filtersReducer from './slices/filtersSlice';
import userReducer from './slices/userSlice';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import diseaseReducer from './slices/diseaseCategorySlice';
import searchReducer from './slices/searchSlice';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user', 'filters', 'disease'],
};

const rootReducer = combineReducers({
    user: userReducer,
    filters: filtersReducer,
    disease: diseaseReducer,
    search: searchReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        },),
});

const persistor = persistStore(store);

export { store, persistor };