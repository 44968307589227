import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import logo from "../companylogo.png"
import { Alert, Button, Paper, Stack, TextField } from "@mui/material"
import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { logout, selectUser } from "../store/slices/userSlice"
import { axiosConfig, handleLogout, handleRefresh, refreshAccessToken, setAccessToken } from "../axiosConfig"
import BreadcrumbNavigation from "../components/BreadcrumbNavigation"
import NavigationAppBar from "../components/NavigationAppBar"
import { hashSync } from "bcrypt-ts"
import { IoWarning } from "react-icons/io5";
import { resetFilters } from "../store/slices/filtersSlice"

type FormValues = {
    currentPassword: string
    newPassword: string
    confirmPassword: string
}

function ChangePassword() {
    const form = useForm<FormValues>({
        defaultValues: {
            currentPassword: '', newPassword: '', confirmPassword: '',
        }
    });
    const { register, handleSubmit, reset, formState, setError } = form;
    const { errors } = formState;
    const navigate = useNavigate();
    const [feedback, setFeedback] = useState<null | number>(null);
    const loggedUser = useSelector(selectUser);
    setAccessToken(loggedUser.accessToken);
    const dispatch = useDispatch();
    const [disableSubmit, setDisableSubmit] = useState(false);
    useEffect(() => {
        if (!loggedUser.isAuthenticated && !loggedUser.email) {
            navigate('/login');
        }
    // eslint-disable-next-line
    }, []);
    const onSubmit = async (data: FormValues) => {
        if (data.newPassword !== data.confirmPassword) {
            setFeedback(1);
            setError("newPassword", { type: 'required', message: '' });
            setError("confirmPassword", { type: 'required', message: '' });
        } else {
            setFeedback(null);
            try {
                setDisableSubmit(true);
                const currentPasswordHash = hashSync(data.currentPassword, process.env.REACT_APP_API_SALT);
                const newPasswordHash = hashSync(data.newPassword, process.env.REACT_APP_API_SALT);
                const confirmPasswordHash = hashSync(data.confirmPassword, process.env.REACT_APP_API_SALT);
                let response: any;
                if (!loggedUser.isAuthenticated && loggedUser.email) {
                    response = await axiosConfig.put('/api/one_time_password', {
                        "email": loggedUser.email,
                        "user_password": currentPasswordHash,
                        "new_password": newPasswordHash,
                        "conf_password": confirmPasswordHash,
                    });
                }else if (loggedUser.isAuthenticated) {
                    response = await axiosConfig.put('/api/change_password', {
                        "user_password": currentPasswordHash,
                        "new_password": newPasswordHash,
                        "conf_password": confirmPasswordHash,
                    });
                }
                if (response.data.status === "SUCCESS") {
                    setFeedback(2);
                    dispatch(logout());
                    dispatch(resetFilters());
                    setAccessToken(null);
                    setDisableSubmit(false);
                    setTimeout(() => {
                        navigate('/login');
                        reset();
                    },0)
                }
            }catch(error: any) {
                if (error.response.data.data === "Incorrect old password") {
                    setDisableSubmit(false);
                    setError("currentPassword", { type: "required", message: "Current password incorrect" });
                }else {
                    try {
                        if (error.response.data.msg === "Token has been revoked") {
                            handleLogout(dispatch, navigate);
                        }
                        if (error.response.data.msg === "Token has expired") {
                            setDisableSubmit(false);
                            const response = await handleRefresh(loggedUser.refreshToken);
                            refreshAccessToken(dispatch, loggedUser, response);
                            document.getElementById('submit')?.click();
                        }
                    }catch(error: any) {
                        if (error.response.data.msg === "Token has expired") {
                            handleLogout(dispatch, navigate);
                        }
                    }
                }
            }
        }
    }

    return (
        <Fragment>
            {loggedUser.isAuthenticated && 
                <Fragment>
                    <div><NavigationAppBar/></div>
                    <BreadcrumbNavigation breadcrumbs={[{path:'/',text:'Home'},{path:'/change-password',text:'Change Password'},]}/>
                </Fragment>}
            <div className="table" style={{ overflow: 'auto', marginTop: '-7px', marginLeft: '-8px', marginRight: '-8px', marginBottom: '10px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: loggedUser.isAuthenticated ? '85vh' : '100vh', backgroundColor: loggedUser.isAuthenticated ? 'white' : '#F1F3F6' }}>
                    <Paper elevation={12} style={{ borderRadius:'10px' }}>
                        <div style={{ textAlign: 'center', backgroundColor: 'white', padding: '20px', paddingTop: '20px', paddingBottom: '30px', border: '1px solid #ccc', borderRadius: '10px' }}>
                            <img style={{ width: '240px', height: '49.19px', marginBottom: '-10px' }} src={logo} alt="company logo"/>
                            {loggedUser.isAuthenticated ? 
                                <p style={{ fontSize: '24px' }}>Change Password</p> :
                                <p style={{ fontSize: '22px' }}><IoWarning size={34} style={{marginBottom: '-8px'}} color="#FF3333"/> Password Change Required</p>}
                            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                                <Stack spacing={2} width={380}>
                                    <TextField autoComplete="off" label="Current Password" type="password" {...register("currentPassword", {
                                        required: {
                                            value: true,
                                            message: "Enter current password"
                                        }
                                    })}
                                    error={!!errors.currentPassword}
                                    helperText={errors.currentPassword?.message}/>
                                    <TextField autoComplete="off" label="New Password" type="password" {...register("newPassword", {
                                        required: {
                                            value: true,
                                            message: "Enter new password"
                                        },
                                        validate: (newPassword) => {
                                            return newPassword.length >= 8  || "Password must be at least 8 characters long";
                                        }
                                    })}
                                    error={!!errors.newPassword}
                                    helperText={errors.newPassword?.message}/>
                                    <TextField autoComplete="off" label="Confirm New Password" type="password" {...register("confirmPassword", {
                                        required: {
                                            value: true,
                                            message: "Reenter new password"
                                        },
                                        validate: (confirmPassword) => {
                                            return confirmPassword.length >= 8 || "Password must be at least 8 characters long";
                                        }
                                    })}
                                    error={!!errors.confirmPassword}
                                    helperText={errors.confirmPassword?.message}/>
                                    {feedback && <Alert style={{fontSize: '16px'}} severity={feedback === 1 ? "error" : "success"}>
                                        {feedback === 1 ? "Passwords does not match" : "Password changed successfully" }</Alert>}
                                    <Button disabled={disableSubmit} id="submit" type="submit" variant="contained" style={{ height: 54, backgroundColor: '#0C73EB', textTransform: 'none', fontSize: '23px', fontWeight: 'bold', color: 'white' }}>Update Password</Button>
                                </Stack>
                            </form>
                            {/*<p style={{fontSize: '16px'}}>Click here to <span style={{ color: '#0C73EB', cursor: 'pointer', fontWeight: 'bold' }} 
                                onMouseEnter={(e) => {(e.target as HTMLElement).style.textDecoration = 'underline'}}
                                onMouseLeave={(e) => {(e.target as HTMLElement).style.textDecoration = 'none'}} onClick={() => { navigate('/login'); }}>Log in</span> to scRNA Portal</p>*/}
                        </div>
                    </Paper>
                </div>
            </div>
        </Fragment>
    );
}

export default ChangePassword;