import { Alert, Button, Paper, Stack, TextField } from "@mui/material";
import logo from "../companylogo.png";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { axiosConfig } from "../axiosConfig";
import { useNavigate } from "react-router-dom";
import { TbReload } from "react-icons/tb";
import { loadCaptchaEnginge, validateCaptcha, LoadCanvasTemplateNoReload } from "react-simple-captcha";
import { CustomPhone } from "../components/CustomPhone";
import { isPhoneValid } from "./SignupForm";

type FormValue = {
    email: string
    phoneNumber: string
    captcha: string
}

function ForgotPassword() {
    useEffect(() => {
        loadCaptchaEnginge(6);
    },[]);

    const form = useForm<FormValue>({
        defaultValues: {
            email: '', phoneNumber: '', captcha: '',
        }
    });
    const [muiPhone, setMuiPhone] = useState("+91");
    const navigate = useNavigate();
    const { register, handleSubmit, reset, formState, setError } = form;
    const { errors } = formState;
    const [feedback, setFeedback] = useState<string>('');
    const [disableSubmit, setDisableSubmit] = useState(false);
    const onSubmit = async (data: FormValue) => {
        try {
            setDisableSubmit(true);
            const response = await axiosConfig.post('/api/forget_password', {"email": data.email, "phone": data.phoneNumber});
            if (response.data.status === "SUCCESS") {
                setDisableSubmit(false);
                setFeedback("Password successfully sent to email");
                setTimeout(() => { 
                    navigate('/login');
                    reset();
                }, 1000);
            }
        }catch(error: any) {
            if (error.response.data.data === "User not found") {
                setDisableSubmit(false);
                setError("email", { type: "required", message: "User not found" });
                setError("phoneNumber",{ type: "required", message: "User not found" });
            }
        }
    };
    return (
        <div className="table" style={{ overflow: 'auto', marginTop: '-7px', marginLeft: '-8px', marginRight: '-8px', marginBottom: '10px' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundColor: '#F1F3F6', margin: '0px'}}>
                <Paper elevation={12} style={{ borderRadius:'10px' }}>
                    <div style={{ textAlign: 'center', backgroundColor: 'white', padding: '20px', paddingTop: '25px', paddingBottom: '25px', border: '1px solid #ccc', borderRadius: '10px' }}>
                        <img style={{ width: '240px', height: '49.19px', marginBottom: '-10px' }} src={logo} alt="company logo"/>
                        <p style={{ fontSize: '24px' }}>Forgot Password</p>
                        <form onSubmit={handleSubmit(onSubmit)} noValidate>
                            <Stack spacing={2} width={380}>
                                <TextField autoComplete="off" label="Email ID" type="email" 
                                    {...register("email", {
                                        required: {
                                            value: true,
                                            message: "Enter your email",
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                            message: "Enter a valid email",
                                        }
                                    })}
                                    error={!!errors.email} 
                                    helperText={errors.email?.message}/>
                                <Controller name="phoneNumber" control={form.control} defaultValue="" 
                                    render={({ field }) => ( <CustomPhone value={muiPhone} onChange={(value) => { setMuiPhone(value); field.onChange(value); }}
                                    helperText={errors.phoneNumber?.message} error={!!errors.phoneNumber} size="medium"/> )}
                                    rules={{ required: 'Enter phone number',
                                        validate: (muiPhone) => {
                                            return isPhoneValid(muiPhone) || "Enter a valid phone number";
                                        },
                                }}/>
                                <div style={{display:'flex'}}>
                                    <LoadCanvasTemplateNoReload/>
                                    <div style={{cursor:'pointer'}} onClick={() => loadCaptchaEnginge(6)}><TbReload/></div>
                                </div>
                                <TextField inputProps={{maxLength: 6}} autoComplete="off" label="Type the text" type="text" 
                                    {...register("captcha", {
                                        required: {
                                            value: true,
                                            message: "Enter the captcha text",
                                        },
                                        validate: (userCaptcha) => {
                                            return validateCaptcha(userCaptcha, false) || "Captcha does not match" ; 
                                        },
                                    })}
                                    error={!!errors.captcha}
                                    helperText={errors.captcha?.message}/>
                                {feedback && <Alert style={{fontSize: '16px'}} severity="success">{feedback}</Alert>}
                                <Button disabled={disableSubmit} type="submit" variant="contained" style={{ height: 54, backgroundColor: '#0C73EB', textTransform: 'none', fontSize: '23px', color: 'white' }}><b>Continue</b></Button>
                            </Stack>
                        </form>
                        <p style={{fontSize: '16px'}}>Click here to <span style={{ color: '#0C73EB', cursor: 'pointer', fontWeight: 'bold' }} 
                            onMouseEnter={(e) => {(e.target as HTMLElement).style.textDecoration = 'underline'}}
                            onMouseLeave={(e) => {(e.target as HTMLElement).style.textDecoration = 'none'}} onClick={() => { navigate('/login'); }}>Log in</span> to scRNA Portal</p>
                    </div>
                </Paper>
            </div>
        </div>
    );
}

export default ForgotPassword;