import React from 'react';
import { Box, Typography, Card, CardContent, CardMedia } from '@mui/material';

interface User {
  username: string | null;
  email: string | null;
  accessToken: string | null;
}

interface FeaturesProps {
  user: User | null;
}

const Features: React.FC<FeaturesProps> = ({ user }) => {
  return (
    <Box
      id="features"
      sx={{
        padding: '4rem 2rem',
        backgroundColor: '#ecf0f1',
        textAlign: 'center'
      }}
    >
      <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
        Key Features
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'space-around', marginTop: '2rem' }}>
        
        
        <Card
          sx={{
            backgroundColor: 'white',
            padding: '2rem',
            width: '30%',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.3s',
            '&:hover': {
              transform: 'scale(1.05)',
            }
          }}
        >
          <CardContent>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Curation Request</Typography>
            <Typography paragraph>
              Submit and manage curation requests for scRNA-seq data with ease.
            </Typography>
            <Box className="video-container">
              <CardMedia
                component="video"
                controls
                width="300"
                poster="path-to-curation-request-thumbnail.jpg"
                src="path-to-curation-request-video.mp4"
                sx={{ marginBottom: '1rem' }}
              />
              <Typography paragraph className="video-description">
                Discover how to efficiently submit and track your curation requests through our streamlined interface.
              </Typography>
            </Box>
          </CardContent>
        </Card>
        
        <Card
          sx={{
            backgroundColor: 'white',
            padding: '2rem',
            width: '30%',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.3s',
            '&:hover': {
              transform: 'scale(1.05)',
            }
          }}
        >
          <CardContent>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Datasets</Typography>
            <Typography paragraph>
              Explore and analyze curated datasets for your research.
            </Typography>
            <Box className="video-container">
              <CardMedia
                component="video"
                controls
                width="300"
                poster="path-to-datasets-thumbnail.jpg"
                src="path-to-datasets-video.mp4"
                sx={{ marginBottom: '1rem' }}
              />
              <Typography paragraph className="video-description">
                Learn how to navigate and utilize the curated datasets for in-depth analysis and research insights.
              </Typography>
            </Box>
          </CardContent>
        </Card>
        
        <Card
          sx={{
            backgroundColor: 'white',
            padding: '2rem',
            width: '30%',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.3s',
            '&:hover': {
              transform: 'scale(1.05)',
            }
          }}
        >
          <CardContent>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Metadata Visualization</Typography>
            <Typography paragraph>
              Visualize metadata associated with your single-cell RNA sequencing experiments.
            </Typography>
            <Box className="video-container">
              <CardMedia
                component="video"
                controls
                width="300"
                poster="path-to-metadata-visualization-thumbnail.jpg"
                src="path-to-metadata-visualization-video.mp4"
                sx={{ marginBottom: '1rem' }}
              />
              <Typography paragraph className="video-description">
                Explore the visualization tools available for metadata and how they can enhance your data analysis.
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default Features;
