import axios from "axios";
import { login, logout } from "./store/slices/userSlice";
import { resetFilters } from "./store/slices/filtersSlice";
import { resetDiseaseParams } from "./store/slices/diseaseCategorySlice";

export const axiosConfig = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

export const setAccessToken = (accessToken: string | null) => {
    if (accessToken) {
        axiosConfig.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }else {
        delete axiosConfig.defaults.headers.common['Authorization'];
    }
};

export const handleLogout = (dispatch: any, navigate: any) => {
    dispatch(logout());
    dispatch(resetFilters());
    navigate('/login');
    dispatch(resetDiseaseParams());
    setAccessToken(null);
};

export const handleRefresh = async (refreshToken: string | null) => {
    const response = await axiosConfig.post('/api/refresh', null, { headers: { Authorization: `Bearer ${refreshToken}`, },});
    return response;
};

export const refreshAccessToken = async (dispatch: any, loggedUser: any, response: any) => {
    dispatch(login({"username": loggedUser.username, "email": loggedUser.email, "isAuthenticated": true, "accessToken": response.data.data, "refreshToken": loggedUser.refreshToken}));
    setAccessToken(response.data.data);
};