import React from 'react';

const Features: React.FC = () => {
  return (
    <section id="features" className="features">
      <h2>Key Features</h2>
      <div className="feature-cards">
        
        <div className="feature-card">
          <h3>Data Curation Portal v1.0</h3>
          <p>Centralize and curate your single-cell RNA sequencing datasets efficiently.</p>
          <div className="video-container">
            <video width="300" controls poster="path-to-data-curation-thumbnail.jpg">
              <source src="path-to-data-curation-video.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <p className="video-description">Watch a quick overview of the Data Curation Portal, highlighting its key features and benefits.</p>
          </div>
        </div>

        <div className="feature-card">
          <h3>Curation Request</h3>
          <p>Submit and manage curation requests for scRNA-seq data with ease.</p>
          <div className="video-container">
            <video width="300" controls poster="path-to-curation-request-thumbnail.jpg">
              <source src="path-to-curation-request-video.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <p className="video-description">Discover how to efficiently submit and track your curation requests through our streamlined interface.</p>
          </div>
        </div>

        <div className="feature-card">
          <h3>Datasets</h3>
          <p>Explore and analyze curated datasets for your research.</p>
          <div className="video-container">
            <video width="300" controls poster="path-to-datasets-thumbnail.jpg">
              <source src="path-to-datasets-video.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <p className="video-description">Learn how to navigate and utilize the curated datasets for in-depth analysis and research insights.</p>
          </div>
        </div>

        <div className="feature-card">
          <h3>Metadata Visualization</h3>
          <p>Visualize metadata associated with your single-cell RNA sequencing experiments.</p>
          <div className="video-container">
            <video width="300" controls poster="path-to-metadata-visualization-thumbnail.jpg">
              <source src="path-to-metadata-visualization-video.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <p className="video-description">Explore the visualization tools available for metadata and how they can enhance your data analysis.</p>
          </div>
        </div>
        
      </div>
    </section>
  );
};

export default Features;
