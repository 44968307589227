import { useLocation, useNavigate } from "react-router-dom";
import logo from "../companylogo.png"
import { useEffect } from "react";
import { FcOk } from "react-icons/fc";
import { Button } from "@mui/material";

function SignupSuccess() {
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (!location.state) {
            navigate('/login');
        }
    });
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh', backgroundColor: 'white', margin: '-8px', paddingBottom: '50vh' }}>
            <div style={{ width:'78%', textAlign: 'left', backgroundColor: 'white', padding: '20px', paddingTop: '20px', paddingBottom: '25px', borderLeft: '0px solid #ccc' }}>
                <img style={{ width: '260px', height: '57.4px', marginLeft: '-8px' }} src={logo} alt="company logo"/>
                <p style={{ fontWeight: 'bold', color: 'seagreen', fontSize: '24px' }}><FcOk size={26} style={{marginBottom: '-4px'}}/> Welcome to scRNA Data Curation Portal</p>
                <p style={{ fontSize: '18px', textAlign: 'left' }}>Congratulations! You've successfully registered with the scRNA Data Curation Portal. Your login credentials are sent to the registered email address provided during signup. You can update your password once you Log in to the portal.</p>
                <p style={{fontSize: '18px'}}>Click on continue button below to Log in to scRNA Portal</p>
                <Button type="submit" variant="contained" style={{ cursor: 'pointer', height: 38, backgroundColor: 'seagreen', textTransform: 'none', fontSize: '20px', fontWeight: 'bold', marginTop: '5px' }} onClick={() => { navigate('/login'); }}>Continue</Button>
            </div>
        </div>
    );
}

export default SignupSuccess;