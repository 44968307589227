import { Link } from "react-router-dom";
import parse from "html-react-parser";
import CurationTable from "../components/CurationTable";

function StudyLevelCurationTable() {
    const handleLinkClick = (urls: any) => {
        urls = urls.split(', ');
        urls.forEach((url: any) => {
            window.open(url, '_blank');
        });
    };
    const config = [
        { Id: 'GEOAccession', label: 'GEO Accession', render: (Datasets: any) => Datasets.GEOAccession , isVisible: true, width: 97.97},
        { Id: 'DatasetExternalURL', label: 'Dataset External URL', render: (Datasets: any) => <Link style={{textDecoration:'none', color: '#0C73EB'}} target="_blank" to={Datasets.DatasetExternalURL}>Link</Link>, isVisible: true, width: 135.5},
        { Id: 'StudyID', label: 'Study ID', render: (Datasets: any) => Datasets.StudyID , isVisible: true, width: 98.98},
        { Id: 'StudyName', label: 'Study Name', render: (Datasets: any) => Datasets.StudyName , isVisible: true, width: 295.42},
        { Id: 'StudyDescription', label: 'Study Description', render: (Datasets: any) => Datasets.StudyDescription , isVisible: true, width: 318.77},
        { Id: 'Organism', label: 'Organism', render: (Datasets: any) => Datasets.Organism , isVisible: true, width: 97.97},
        { Id: 'PubMedID', label: 'PubMed ID', render: (Datasets: any) => Datasets.PubMedID , isVisible: true, width: 70.48},
        { Id: 'PublicationExternalURL', label: 'Publication External URL', render: (Datasets: any) => Datasets.PublicationExternalURL.length === 0 ? "null" : <Link style={{textDecoration:'none', color: '#0C73EB'}} to="#" onClick={() => handleLinkClick(Datasets.PublicationExternalURL)}>{parse(Datasets.ReferencePublication)}</Link> , isVisible: true, width: 365.8},
        { Id: 'GSEPublicationDate', label: 'Publication Date', render: (Datasets: any) => Datasets.GSEPublicationDate , isVisible: true, width: 105.97},
        { Id: 'GSELastUpdateDate', label: 'Last Update Date', render: (Datasets: any) => Datasets.GSELastUpdateDate , isVisible: true, width: 110.14}
    ];
    return <CurationTable config={config} curating="study" navButtons={["Experiment", "Sample"]} width={2020} rowkey="StudyID"/>;
}

export default StudyLevelCurationTable;