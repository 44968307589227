import { IoClose, IoSearchOutline } from "react-icons/io5";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetFilters } from "../store/slices/filtersSlice";
import { Button } from "@mui/material";
import { selectSearchKey, setSearchKey } from "../store/slices/searchSlice";

function SearchBar() {
    const [searchString, setSearchString] = useState<string>('');
    const dispatch = useDispatch();
    const searchKey = useSelector(selectSearchKey);
    const settingSearchKey = (key: string) => {
        dispatch(setSearchKey(key));
        dispatch(resetFilters());
    };
    const handleKeyDown = (event: any) => {
        if (event.key === "Enter" && searchString.length > 0) {
            settingSearchKey(searchString.trim());
        }
    };
    const handleChange = (event: any) => {
        setSearchString(event.target.value);
        if ((event.target.value).trim().length === 0 && searchKey !== '') {
            settingSearchKey('');
        }
    };
    useEffect(() => {
        setSearchString(searchKey);
    }, [searchKey])
    const handleClear = () => {
        setSearchString('');
        settingSearchKey('');
    }
    return (
        <div style={{ position: 'relative', margin: '5px', backgroundColor: 'white', overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderRadius: '5px' }}>
            <input value={searchString} onKeyDown={handleKeyDown} onChange={handleChange} type='text' placeholder='Search' 
                style={{ width: '180px', padding: '0 10px', fontSize: '14px', flex: 1, height: '33px', backgroundColor: 'transparent', border: '1px solid #ccc', borderTopLeftRadius: '5px', borderBottomLeftRadius: '5px' }}/>
            {searchString.length > 0 && (
                <div style={{ position: 'absolute', right: '52px', backgroundColor: 'white', border: 'none', zIndex: 1, paddingTop: '4px' }}>
                    <IoClose size={20.5} color='black' style={{ cursor: 'pointer' }} onClick={handleClear}/>
                </div>
            )}
            <Button disableElevation size="small" variant="contained" sx={{ minWidth: '46.5px', paddingLeft: 0, paddingRight: 0 }} style={{ color: 'black', borderRight: '1px solid #ccc', borderTop: '1px solid #ccc', borderBottom: '1px solid #ccc', backgroundColor: 'white', height: '35px', display: 'flex', alignItems: 'center', cursor: 'pointer', borderTopRightRadius: '5px', borderBottomRightRadius: '5px', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }} 
                onClick={() => settingSearchKey(searchString.trim())}>
                <IoSearchOutline style={{ padding: 0 }} size={16.5} color={searchKey.length > 0 ? "#0C73EB" : 'black'}/>
            </Button>
        </div>
    );
}

export default SearchBar;