import { useDispatch, useSelector } from "react-redux";
import { selectDiseaseCategory, selectDiseaseType, selectDiseaseTypeList, updateDiseaseCategory, updateDiseaseType, updateDiseaseTypeList } from "../store/slices/diseaseCategorySlice";
import { resetFilters } from "../store/slices/filtersSlice";
import { Fragment, useEffect } from "react";
import { axiosConfig, handleLogout, handleRefresh, refreshAccessToken } from "../axiosConfig";
import { useNavigate } from "react-router-dom";
import { selectUser } from "../store/slices/userSlice";

function SelectDiseaseCategory({ setFilterApplied }: any) {
    const diseaseCategory = useSelector(selectDiseaseCategory);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loggedUser = useSelector(selectUser);
    const diseaseTypeList = useSelector(selectDiseaseTypeList);
    const diseaseType = useSelector(selectDiseaseType);
    const fetchDiseaseType = async () => {
        try {
            const response = await axiosConfig.get(`/api/disease_category?DiseaseCategory=${diseaseCategory}`);
            if (!((response.data.data)[diseaseCategory].includes(diseaseType))) {
                dispatch(updateDiseaseType(diseaseCategory === "Inflammatory Bowel Disease" ? "" : (response.data.data)[diseaseCategory][0]));
                dispatch(updateDiseaseTypeList((response.data.data)[diseaseCategory]));
            }
        }catch (error: any) {
            try{
                if (error.response.data.msg === "Token has been revoked") {
                    handleLogout(dispatch, navigate);
                }
                if (error.response.data.msg === "Token has expired") {
                    const response = await handleRefresh(loggedUser.refreshToken);
                    refreshAccessToken(dispatch, loggedUser, response);
                    const refreshedResponse = await axiosConfig.get(`/api/disease_category?DiseaseCategory=${diseaseCategory}`);
                    dispatch(updateDiseaseType(diseaseCategory === "Inflammatory Bowel Disease" ? "" : (refreshedResponse.data.data)[diseaseCategory][0]));
                    dispatch(updateDiseaseTypeList((refreshedResponse.data.data)[diseaseCategory]));
                }
            }catch (error: any) {
                if (error.response.data.msg === "Token has expired") {
                    handleLogout(dispatch, navigate);
                }
            }
        }
    };

    useEffect(() => {
        setFilterApplied(false);
        fetchDiseaseType();
        // eslint-disable-next-line
    }, [diseaseCategory]);

    const handleCategoryChange = (event: any) => {
        dispatch(resetFilters());
        dispatch(updateDiseaseCategory(event.target.value));
    };

    const handleTypeChange = (event: any) => {
        dispatch(resetFilters());
        dispatch(updateDiseaseType(event.target.value));
    };
    return (
        <Fragment>
            <select name="diseaseCategory" id="selectDiseaseCategory" value={diseaseCategory} onChange={handleCategoryChange}
                style={{ width: '222px', height: '32px', margin: '5px', borderRadius: '5px', backgroundColor: 'white', border: '1px solid lightgray' }}>
                <option value="" disabled>Select Disease Category</option>
                <option value="Inflammatory Bowel Disease">Inflammatory Bowel Disease</option>
                <option value="Neurodegenerative Disease">Neurodegenerative Disease</option>
            </select>
            {diseaseCategory !== "Inflammatory Bowel Disease" && <select name="diseaseType" id="selectDiseaseType" value={diseaseType as string} onChange={handleTypeChange}
                style={{ width: '222px', height: '32px', margin: '5px', borderRadius: '5px', backgroundColor: 'white', border: '1px solid lightgray' }}>
                <option value="" disabled>Select Disease Type</option>
                {diseaseTypeList.map((option) => <option key={option} value={option}>{option}</option>)}
            </select>}
        </Fragment>
    );
}

export default SelectDiseaseCategory;