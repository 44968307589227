import ContentLoader from "react-content-loader";

function DataTableLoader() {
    return (
        <ContentLoader speed={1} width={1570} height={870} viewBox="0 0 1570 870" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
            <rect x="20" y="20" rx="3" ry="3" width="1530" height="50" />
            <rect x="20" y="80" rx="3" ry="3" width="1530" height="180" />
            <rect x="20" y="280" rx="3" ry="3" width="1530" height="180" />
            <rect x="20" y="480" rx="3" ry="3" width="1530" height="180" />
            <rect x="20" y="680" rx="3" ry="3" width="1530" height="180" />
        </ContentLoader>
    );
}

function CurationRequestLoader() {
    return (
        <ContentLoader speed={1} width={1880} height={860} viewBox="0 0 1880 860" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
            <rect x="5" y="20" rx="3" ry="3" width="1820" height="50" />
            <rect x="5" y="80" rx="3" ry="3" width="1820" height="50" />
            <rect x="5" y="140" rx="3" ry="3" width="1820" height="50" />
            <rect x="5" y="200" rx="3" ry="3" width="1820" height="50" />
            <rect x="5" y="260" rx="3" ry="3" width="1820" height="50" />      
            <rect x="5" y="320" rx="3" ry="3" width="1820" height="50" />
            <rect x="5" y="380" rx="3" ry="3" width="1820" height="50" />
            <rect x="5" y="440" rx="3" ry="3" width="1820" height="50" />
            <rect x="5" y="500" rx="3" ry="3" width="1820" height="50" />
            <rect x="5" y="560" rx="3" ry="3" width="1820" height="50" />
            <rect x="5" y="620" rx="3" ry="3" width="1820" height="50" />
            <rect x="5" y="680" rx="3" ry="3" width="1820" height="50" />
            <rect x="5" y="740" rx="3" ry="3" width="1820" height="50" />
            <rect x="5" y="800" rx="3" ry="3" width="1820" height="50" />
        </ContentLoader>
    );
}

function MetaDataLoader() {
    return (
        <ContentLoader speed={1} width={1848} height={966} viewBox="0 0 1848 966" backgroundColor="#f3f3f3" foregroundColor="#ecebeb">
            <rect x="18" y="98" rx="2" ry="2" width="898" height="350" />
            <rect x="932" y="98" rx="2" ry="2" width="898" height="350" />
            <rect x="18" y="465" rx="2" ry="2" width="898" height="350" />
            <rect x="932" y="465" rx="2" ry="2" width="898" height="350" />
        </ContentLoader>
    );
}

export default DataTableLoader;
export { CurationRequestLoader, MetaDataLoader };