import { Fragment, useState } from "react";
import sanitizeHtml from 'sanitize-html';
import parse from 'html-react-parser';

function WrapCell({ column }: any) {
    const [showMore, setShowMore] = useState(true);
    const handleClick = () => {
        setShowMore(!showMore);
    };
    return (
        <Fragment>
            {showMore ? 
                (column.length > 103 ? 
                    <span>{parse(sanitizeHtml(column.slice(0,103), {disallowedTagsMode: 'escape'}))}<span style={{color:'#0C73EB',cursor:'pointer'}} onClick={handleClick}> ... Read more</span></span> : 
                    parse(sanitizeHtml(column, {disallowedTagsMode: 'escape'}))) :
                <span>{parse(sanitizeHtml(column, {disallowedTagsMode: 'escape'}))}<span style={{color:'red',cursor:'pointer'}} onClick={handleClick}> Read less</span></span>}
        </Fragment>
    );
}

export default WrapCell;