import React, { useState, useEffect } from 'react';
import land1 from './land1.png';
import download from './download.png';
import land4 from './land4.png';

const images: string[] = [land1, download, land4];

const ImageCarousel: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        padding: '20px',
        boxSizing: 'border-box',
        backgroundColor: '#f8f9fa',
        marginLeft: '50px',
      }}
    >
      <div
        style={{
          flex: 1,
          paddingRight: '20px',
          maxWidth: '500px',
          marginLeft: '120px',
        }}
      >
        <h2 style={{ fontSize: '24px', marginBottom: '16px', color: '#333' }}>
          Refining Genomic Insights, One Cell At A Time
        </h2>
        <p
          style={{
            fontSize: '16px',
            marginBottom: '20px',
            color: '#555',
            lineHeight: 1.5,
          }}
        >
          Our portal is your gateway to a world of meticulously curated
          single-cell RNA sequencing data, providing clarity and depth in the
          field of genomics. We curate publicly available datasets and offer
          custom curation of confidential datasets.
        </p>
        <p
          style={{
            fontSize: '16px',
            marginBottom: '20px',
            color: '#555',
            lineHeight: 1.5,
          }}
        >
          Navigate effortlessly through our user-friendly interface to access
          curated data and accelerate your research to unleash its full
          potential.
        </p>
        <button
          style={{
            backgroundColor: '#007bff',
            color: '#fff',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '5px',
            fontSize: '16px',
            cursor: 'pointer',
          }}
          onMouseOver={(e) =>
            (e.currentTarget.style.backgroundColor = '#0056b3')
          }
          onMouseOut={(e) =>
            (e.currentTarget.style.backgroundColor = '#007bff')
          }
        >
          Get Started
        </button>
      </div>
      <div
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={images[currentIndex]}
          alt="Single-Cell Research"
          style={{
            width: '600px',
            height: '400px',
            objectFit: 'cover',
            borderRadius: '8px',
          }}
        />
      </div>
    </div>
  );
};

export default ImageCarousel;
