import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const Footer: React.FC = () => {
  return (
    <Box sx={{ padding: '2rem', backgroundColor: '#a7bfdb', color: 'rgb(22, 20, 20)', textAlign: 'center' }}>
      <Typography variant="body2">
        © 2024 scRNA-seq Portal. All rights reserved.
      </Typography>
      <Box sx={{ marginTop: '1rem' }}>
        <Link href="#!" sx={{ marginRight: '1rem', color: 'rgb(8, 8, 8)', textDecoration: 'none' }}>Twitter</Link>
        <Link href="#!" sx={{ marginRight: '1rem', color: 'rgb(8, 8, 8)', textDecoration: 'none' }}>LinkedIn</Link>
        <Link href="#!" sx={{ color: 'rgb(8, 8, 8)', textDecoration: 'none' }}>GitHub</Link>
      </Box>
    </Box>
  );
};

export default Footer;
