import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";

function RowSelectionDialog({openDialog, setOpenDialog}: any) {
    return (
        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogContent>
                <DialogContentText id="alertdialogtext">
                    <b>No rows selected. Please select row(s) to continue</b>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button style={{ backgroundColor:'#0C73EB', color:'white', fontWeight: 'bold'}} onClick={() => setOpenDialog(false)}>OK</Button>
            </DialogActions>
        </Dialog>
    );
}

export default RowSelectionDialog;