import { Fragment } from "react";
import NavigationAppBar from "../components/NavigationAppBar";
import logo from "../companylogo.png";
import { Button, Stack } from "@mui/material";
import icon from "../companyicon.png";
import { IoMail } from "react-icons/io5";

function HomePage() {
    return (
        <Fragment>
            <div><NavigationAppBar/></div>
            <div className="table">
            <div style={{ textAlign:'justify', textJustify: 'inter-word', paddingTop: '0.5%', paddingBottom: '0.5%', paddingLeft: '8.2%', paddingRight:'13.6%', display:'flex',justifyContent:'center',alignItems:'center',fontFamily:'Lato'}}>
                <Stack>
                    <Stack direction="row" style={{marginLeft:'-8.4%'}}>
                        <img style={{ width: '4.151vw', height: '4.151vw', paddingTop: '0.6%', paddingLeft: '0.62%' }} src={icon} alt="company logo"/>
                        <p style={{paddingBottom:'5.7%',whiteSpace:'pre',fontSize:'1.625vw',fontWeight:'bold', paddingLeft:'0.96%'}}>Welcome to the Single-cell RNA Data Curation Portal v1.0</p>
                    </Stack>
                    <Stack direction="row">
                        <div style={{textAlign:'center',border:'1px solid #ccc',backgroundColor:'#F1F3F6',width:'23vw',height:'23vw',borderRadius:'50%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <Stack spacing={0.5}>
                                <img style={{ width: '22.783vw', height: '4.875vw' }} src={logo} alt="company logo"/>
                            </Stack>
                        </div>
                        <Stack spacing={1}>
                            <p style={{paddingLeft: '11.1%', paddingTop: '9.08%', fontSize:'1.625vw',color:'#0c73eb',fontWeight:'bold'}}>Refining Genomic Insights, One Cell At A Time</p>
                            <p style={{paddingLeft: '11.1%', paddingTop: '3.53%', fontSize:'1.19vw'}}>Our portal is your gateway to a world of meticulously curated single-cell RNA sequencing data, providing clarity and depth in the field of genomics.  We curate publicly available datasets and offer custom curation of confidential datasets.</p>
                            <p style={{paddingLeft: '11.1%', paddingTop: '3.53%', fontSize:'1.19vw'}}>Navigate effortlessly through our user-friendly interface to access curated data and accelerate your research to unleash its full potential.</p>
                        </Stack>
                    </Stack>
                    <Stack direction="row" style={{paddingTop: '5%', paddingLeft:'0.9%'}}>
                        <p style={{fontSize:'1.19vw'}}>Join us in refining genomics, one cell at a time!</p>
                        <Button variant="text" style={{ color: 'black', textTransform: 'none', fontSize: '1.19vw', whiteSpace: 'pre', width: '9.22vw', height: '1.9vw', marginTop: '1.26%', marginLeft:'4.6%' }} href="mailto:bioinformatics@strandls.com">
                                    <IoMail style={{marginRight:'0.53vw',marginTop:'-0.159vw', marginLeft:'-1.06vw' }} size="1.4vw"/>Contact Us</Button>
                    </Stack>
                </Stack>
            </div>
            </div>
        </Fragment>
    );
}

export default HomePage;