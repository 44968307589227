import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Features from '../components/Features';
import Footer from '../components/Footer';
import ImageCarousel from '../components/ImageCarousel';
import { selectUser } from '../store/slices/userSlice';
import { setAccessToken } from '../axiosConfig';

const LandingPage: React.FC = () => {
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/signup');
    }
  }, [user, navigate]);

  useEffect(() => {
    if (user) {
      setAccessToken(user.accessToken);
    }
  }, [user]);

  if (!user) {
    return null; 
  }

  return (
    <div 
      style={{
        textAlign: 'center',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
      }}
    >
      <Header />
      <div style={{ marginTop: '5rem' }}>
        <ImageCarousel />
      </div>
      <div style={{ padding: '50px', minHeight: '500px' }}>
        <Features user={user} />
      </div>
      <div 
        style={{
          marginTop: 'auto',
          padding: '1rem',
          backgroundColor: '#f1f1f1',
          textAlign: 'center',
          width: '100%',
        }}
      >
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
