import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <p>© 2024 scRNA-seq Portal. All rights reserved.</p>
      <div className="social-links">
        <a href="#!">Twitter</a>
        <a href="#!">LinkedIn</a>
        <a href="#!">GitHub</a>
      </div>
    </footer>
  );
};

export default Footer;
