import { Fragment, useEffect, useState } from "react";
import domtoimage from 'dom-to-image';
import fileDownload from "js-file-download";
import { Button, Menu, MenuItem } from "@mui/material";
import * as XLSX from "xlsx";
import { GoChevronDown, GoChevronUp } from "react-icons/go";

function ExportGraph({ onX, onY, chart, data }: any) {
    const [hideExport, setHideExport] = useState<boolean>(true);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    useEffect(() => {
        if (!hideExport) {
            setHideExport(true);
        }
    }, [hideExport]);
    const handlePngDownload = () => {
        setHideExport(false);
        domtoimage.toBlob(document.getElementById(onX+onY),{bgcolor:'white'}).then(
            function (blob: any) {
                fileDownload(blob, onX+ " Vs "+ onY+ '.png');
            });
    }
    const handleJpegDownload = () => {
        setHideExport(false);
        domtoimage.toJpeg(document.getElementById(onX+onY),{bgcolor:'white'}).then(
            function (dataUrl: any) {
                const link = document.createElement('a');
                link.download = onX+" Vs "+onY+'.jpeg';
                link.href = dataUrl;
                link.click();
            }
        );
    }
    const filter = (node: any) => {
        return (node.tagName !== 'i');
    }
    const handleSvgDownload = () => {
        setHideExport(false);
        domtoimage.toSvg(document.getElementById(onX+onY),{filter:filter}).then(
            function (dataUrl: any) {
                const link = document.createElement('a');
                link.download = onX+" Vs "+onY+'.svg';
                link.href = dataUrl;
                link.click();
            }
        );
    }
    const exportData = data.map((row: any) => {
        return { [onX]: chart === 'pie' ? row.name : row[onX], [onY]: row[onY] };
    });
    const handleCsvDownload = () => {
        const ws = XLSX.utils.json_to_sheet(exportData);
        const csv = XLSX.utils.sheet_to_csv(ws);
        fileDownload(new Blob([csv]),onX+" Vs "+onY+'.csv');
    }
    const handleXlsxDownload = () => {
        const ws = XLSX.utils.json_to_sheet(exportData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb,ws,'Sheet 1');
        XLSX.writeFile(wb, onX+" Vs "+onY+'.xlsx', { bookType: 'xlsx' } );
    }
    const renderedExportMenu = (
        <Fragment>
            {open ? <Button size="medium" variant="contained" style={{float:'right',marginRight:'5px',color:'black',backgroundColor:'#F1F3F6',textTransform:'none',height:'1.185vw',border:'1px solid #666',padding:0,paddingLeft:'0.22vw',paddingRight:'0.11vw',fontSize:'0.754vw'}} onClick={(event) => { setAnchorEl(event?.currentTarget); }}>Export<GoChevronUp style={{paddingLeft:'1px',paddingBottom:'2px'}} size="0.8083vw"/></Button> : 
                <Button size="medium" variant="contained" style={{float:'right',marginRight:'5px',color:'black',backgroundColor:'#F1F3F6',textTransform:'none',height:'1.185vw',border:'1px solid #666',padding:0,paddingLeft:'0.22vw',paddingRight:'0.11vw',fontSize:'0.754vw'}} onClick={(event) => { setAnchorEl(event?.currentTarget); }}>Export<GoChevronDown style={{paddingLeft:'1px'}} size="0.8083vw"/></Button>}
            <Menu id="exportmenu" anchorEl={anchorEl} open={open} onClose={() => { setAnchorEl(null); }} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                <MenuItem onClick={() => { setAnchorEl(null); handlePngDownload(); }}>export as png</MenuItem>
                <MenuItem onClick={() => { setAnchorEl(null); handleJpegDownload(); }}>export as jpeg</MenuItem>
                <MenuItem onClick={() => { setAnchorEl(null); handleSvgDownload(); }}>export as svg</MenuItem>
                <MenuItem onClick={() => { setAnchorEl(null); handleCsvDownload(); }}>export as csv</MenuItem>
                <MenuItem onClick={() => { setAnchorEl(null); handleXlsxDownload(); }}>export as xlsx</MenuItem>
            </Menu>
        </Fragment>
    );
    return (
        <Fragment>
            {hideExport && renderedExportMenu}
        </Fragment>
    );
}

export default ExportGraph;