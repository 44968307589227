import CurationTable from "../components/CurationTable";

function ExperimentLevelCurationTable() {
    const config = [
        {Id: 'GEOAccession', label: 'GEO Accession', render: (Datasets: any) => Datasets.GEOAccession, isVisible: true, width: 97.97 },
        {Id: 'StudyID', label: 'Study ID', render: (Datasets: any) => Datasets.StudyID, isVisible: true, width: 87.58 },
        {Id: 'ExperimentTag', label: 'Experiment Tag', render: (Datasets: any) => Datasets.ExperimentTag, isVisible: true, width: 190.58 },
        {Id: 'ExperimentDescription', label: 'Experiment Description', render: (Datasets: any) => Datasets.ExperimentDescription, isVisible: true, width: 285.36 },
        {Id: 'MeasurementType', label: 'Measurement Type', render: (Datasets: any) => Datasets.MeasurementType, isVisible: true, width: 152.61 },
        {Id: 'TechnologyType', label: 'Technology Type', render: (Datasets: any) => Datasets.TechnologyType, isVisible: true, width: 139.56 },
        {Id: 'PlatformID', label: 'Platform ID', render: (Datasets: any) => Datasets.PlatformID, isVisible: true, width: 93.64 },
        {Id: 'SequencingPlatform', label: 'Sequencing Platform', render: (Datasets: any) => Datasets.SequencingPlatform, isVisible: true, width: 171.39 },
        {Id: 'CellSortingTechnique', label: 'Cell Sorting Technique', render: (Datasets: any) => Datasets.CellSortingTechnique, isVisible: true, width: 157.56 },
        {Id: 'LibraryPreparationPlatform', label: 'Library Preparation Platform', render: (Datasets: any) => Datasets.LibraryPreparationPlatform, isVisible: true, width: 195 },
        {Id: 'LibraryProcessingProtocol', label: 'Library Processing Protocol', render: (Datasets: any) => Datasets.LibraryProcessingProtocol, isVisible: true, width: 200.11 },
        {Id: 'Sequencer', label: 'Sequencer', render: (Datasets: any) => Datasets.Sequencer, isVisible: true, width: 150.13 },
        {Id: 'SequenceDataAligner', label: 'Sequence Data Aligner', render: (Datasets: any) => Datasets.SequenceDataAligner, isVisible: true, width: 173.28 },
        {Id: 'SequenceDataProcessor', label: 'Sequence Data Processor', render: (Datasets: any) => Datasets.SequenceDataProcessor, isVisible: true, width: 183.27 },
        {Id: 'NumberOfSamples', label: 'Number Of Samples', render: (Datasets: any) => Datasets.NumberOfSamples, isVisible: true, width: 129.2 },
        {Id: 'AnatomicalEntity', label: 'Tissue', render: (Datasets: any) => Datasets.AnatomicalEntity, isVisible: true, width: 117.38 },
        {Id: 'Disease', label: 'Disease', render: (Datasets: any) => Datasets.Disease, isVisible: true, width: 113.25 },
        {Id: 'DiseaseCategory', label: 'Disease Category', render: (Datasets: any) => Datasets.DiseaseCategory, isVisible: true, width: 122.89 },
        {Id: 'CreatedAt', label: 'Created At', render: (Datasets: any) => Datasets.CreatedAt, isVisible: true, width: 235.05 },
        {Id: 'Contributor', label: 'Contributor', render: (Datasets: any) => Datasets.Contributor, isVisible: true, width: 94.28 },
        {Id: 'ExperimentYear', label: 'Experiment Year', render: (Datasets: any) => Datasets.ExperimentYear, isVisible: true, width: 105.66 },
        {Id: 'Submittor', label: 'Submittor', render: (Datasets: any) => Datasets.Submittor, isVisible: false, width: 63.8 },
        {Id: 'Approver', label: 'Approver', render: (Datasets: any) => Datasets.Approver, isVisible: false, width: 58.39 },
        {Id: 'CellLine', label: 'Cell Line', render: (Datasets: any) => Datasets.CellLine, isVisible: true, width: 240.83 },
        {Id: 'CellType', label: 'Cell Type', render: (Datasets: any) => Datasets.CellType, isVisible: true, width: 265.77 },
        {Id: 'Age', label: 'Age', render: (Datasets: any) => Datasets.Age, isVisible: true, width: 240.77 },
        {Id: 'MinimumAge', label: 'Minimum Age', render: (Datasets: any) => Datasets.MinimumAge, isVisible: true, width: 94.19 },
        {Id: 'MaximumAge', label: 'Maximum Age', render: (Datasets: any) => Datasets.MaximumAge, isVisible: true, width: 96.81 },
        {Id: 'Gender', label: 'Gender', render: (Datasets: any) => Datasets.Gender, isVisible: true, width: 54.58 },
        {Id: 'SampleAcquisitionMethod', label: 'Sample Acquisition Method', render: (Datasets: any) => Datasets.SampleAcquisitionMethod, isVisible: true, width: 177.61 },
        {Id: 'DiseaseStatus', label: 'Disease Status', render: (Datasets: any) => Datasets.DiseaseStatus, isVisible: true, width: 103.69 },
        {Id: 'Treatment', label: 'Treatment', render: (Datasets: any) => Datasets.Treatment, isVisible: true, width: 254 },
        {Id: 'Timepoint', label: 'Timepoint', render: (Datasets: any) => Datasets.Timepoint, isVisible: true, width: 74.78 },
        {Id: 'NumberOfCells', label: 'Number of Cells', render: (Datasets: any) => Datasets.NumberOfCells, isVisible: true, width: 103.91 },
        {Id: 'SRAID', label: 'SRA ID', render: (Datasets: any) => Datasets.SRAID, isVisible: true, width: 74.28 },
        {Id: 'Keywords', label: 'Keywords', render: (Datasets: any) => Datasets.Keywords, isVisible: true, width: 285.88 }
    ];
    return <CurationTable config={config} curating="experiment" navButtons={["Study", "Sample"]} width={6400} rowkey="StudyID"/>;
}

export default ExperimentLevelCurationTable;