import { createSlice } from "@reduxjs/toolkit";

interface LoggedUser {
    username: null | string
    email: null | string
    isAuthenticated: boolean
    accessToken: null | string
    refreshToken: null | string
}

interface UserState {
    loggedUser: LoggedUser;
}

const userSlice = createSlice({
    name: "user",
    initialState: {
        loggedUser: {
            "username": null,
            "email": null,
            "isAuthenticated": false,
            "accessToken": null,
            "refreshToken": null,
        },
    },
    reducers: {
        login: (state, action) => {
            state.loggedUser = action.payload;
        },
        logout : (state) => {
            state.loggedUser = {
                "username": null,
                "email": null,
                "isAuthenticated": false,
                "accessToken": null,
                "refreshToken": null,
            };
        }
    }
});

export const { login, logout } = userSlice.actions;
export const selectUser = (state: { user: UserState }) => state.user.loggedUser;
export default userSlice.reducer;