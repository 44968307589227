import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, logout } from '../store/slices/userSlice'; 
import AppBar from '@mui/material/AppBar';
import { Link, useNavigate } from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { GoChevronDown } from 'react-icons/go';
import logo from "../companylogo.png";
import { BsDatabaseFill, BsGraphUpArrow } from 'react-icons/bs';
import { AiFillDatabase } from 'react-icons/ai';
import { NestedMenuItem } from 'mui-nested-menu';
import Button from '@mui/material/Button';
import { updateDiseaseCategory } from '../store/slices/diseaseCategorySlice';
import { resetFilters } from '../store/slices/filtersSlice';
import SearchBar from './SearchBar';
import { axiosConfig } from '../axiosConfig';
import { IconButton, Avatar, Menu, MenuItem, Divider, ListItemText, Box } from '@mui/material';

const Header: React.FC = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleLogoutButton = async () => {
    try {
      const response = await axiosConfig.post('/api/logout');
      if (response.data.status === "SUCCESS") {
        dispatch(logout());
        navigate('/'); 
      }
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const handleRedirectIfNotAuthenticated = (redirectPath: string) => {
    if (!user.isAuthenticated) {
      navigate('/login'); 
    } else {
      navigate(redirectPath);
    }
  };
  const linkStyles = { textDecoration: 'none', color: '#007bff' };
  const typographyStyles = { marginRight: '35px', fontSize: '12pt', cursor: 'pointer' };

  const CurationRequest = () => {
    const [curationAnchorEl, setCurationAnchorEl] = React.useState<null | HTMLElement>(null);
    const curationOpen = Boolean(curationAnchorEl);

    return (
      <>
        <Typography variant="h6" component="div" sx={typographyStyles} onClick={(event) => setCurationAnchorEl(event.currentTarget)}>
          <b style={{ color: curationOpen ? '#0C73EB' : 'black' }}>
            <AiFillDatabase size={18} style={{ marginRight: '5px' }} />Curation Request<GoChevronDown />
          </b>
        </Typography>
        <Menu anchorEl={curationAnchorEl} open={curationOpen} onClose={() => setCurationAnchorEl(null)}>
          <Link to="/create-curation-request" style={linkStyles}>
            <MenuItem onClick={() => setCurationAnchorEl(null)}>Create Curation Request</MenuItem>
          </Link>
          <Link to="/curation-requests" style={linkStyles}>
            <MenuItem onClick={() => setCurationAnchorEl(null)}>My Curation Requests</MenuItem>
          </Link>
        </Menu>
      </>
    );
  };

  const Datasets = () => {
    const [datasetAnchorEl, setDatasetAnchorEl] = React.useState<null | HTMLElement>(null);
    const datasetOpen = Boolean(datasetAnchorEl);
  
    const handleDatasetMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
      setDatasetAnchorEl(event.currentTarget);
    };
  
    const handleDatasetMenuClose = () => {
      setDatasetAnchorEl(null);
    };
  
    const handleDiseaseCategoryChange = (selectedDiseaseCategory: string) => {
      dispatch(resetFilters());
      dispatch(updateDiseaseCategory(selectedDiseaseCategory));
      handleDatasetMenuClose(); 
    };
  
    return (
      <>
        <Typography variant="h6" component="div" sx={typographyStyles} onClick={handleDatasetMenuOpen}>
          <b style={{ color: datasetOpen ? '#0C73EB' : 'black' }}>
            <BsDatabaseFill size={18} style={{ marginRight: '6px' }} />Datasets<GoChevronDown />
          </b>
        </Typography>
        <Menu anchorEl={datasetAnchorEl} open={datasetOpen} onClose={handleDatasetMenuClose}>
          <Link to="/publicly-curated-datasets" style={linkStyles}>
            <MenuItem onClick={handleDatasetMenuClose}>Curated Datasets from Public Resources</MenuItem>
          </Link>
          <NestedMenuItem label="Study Level Curation" parentMenuOpen={datasetOpen}>
            <Link to="/study-table" style={linkStyles}>
              <MenuItem onClick={() => handleDiseaseCategoryChange("Inflammatory Bowel Disease")}>Inflammatory Bowel Disease</MenuItem>
            </Link>
            <Link to="/study-table" style={linkStyles}>
              <MenuItem onClick={() => handleDiseaseCategoryChange("Neurodegenerative Disease")}>Neurodegenerative Disease</MenuItem>
            </Link>
          </NestedMenuItem>
          <NestedMenuItem label="Experiment Level Curation" parentMenuOpen={datasetOpen}>
            <Link to="/experiment-table" style={linkStyles}>
              <MenuItem onClick={() => handleDiseaseCategoryChange("Inflammatory Bowel Disease")}>Inflammatory Bowel Disease</MenuItem>
            </Link>
            <Link to="/experiment-table" style={linkStyles}>
              <MenuItem onClick={() => handleDiseaseCategoryChange("Neurodegenerative Disease")}>Neurodegenerative Disease</MenuItem>
            </Link>
          </NestedMenuItem>
          <NestedMenuItem label="Sample Level Curation" parentMenuOpen={datasetOpen}>
            <Link to="/sample-table" style={linkStyles}>
              <MenuItem onClick={() => handleDiseaseCategoryChange("Inflammatory Bowel Disease")}>Inflammatory Bowel Disease</MenuItem>
            </Link>
            <Link to="/sample-table" style={linkStyles}>
              <MenuItem onClick={() => handleDiseaseCategoryChange("Neurodegenerative Disease")}>Neurodegenerative Disease</MenuItem>
            </Link>
          </NestedMenuItem>
          <Link to="/documentation" style={linkStyles}>
            <MenuItem onClick={handleDatasetMenuClose}>Documentation</MenuItem>
          </Link>
        </Menu>
      </>
    );
  };
  
  return (
    <AppBar position="static" sx={{ backgroundColor: '#a7bfdb', height: '60px', border: '1px solid #ccc' }}>
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt="Company Logo" style={{ width: '250px', height: 'auto' }} />
        </Box>
        <Typography variant="h6" component="div" sx={{ marginRight: '80px', flexGrow: 1 }}>
          <span style={{ fontSize: '15.5px', color: 'black', whiteSpace: 'pre' }}><b>Single-cell RNA sequencing</b></span>
          <br />
          <center>
            <div style={{ fontSize: '15px', color: 'gray', marginTop: '-6.5px', marginBottom: '10px' }}>
              <b>Data curation portal v1.0</b>
            </div>
          </center>
          </Typography>
        <nav style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
          <>
            <CurationRequest />
            <Datasets />
            <Typography variant="h6" component="div" sx={typographyStyles}>
              <span onClick={() => handleRedirectIfNotAuthenticated('/metadata-visualization')}>
                <b><BsGraphUpArrow size={18} style={{ marginRight: '10px', color: 'black' }} />Metadata Visualization</b>
              </span>
            </Typography>
          </>
          <Button
            variant="contained"
            onClick={() => navigate('/book-demo')}
            sx={{
              background: '#007bff',
              color: 'white',
              fontWeight: 'bold',
              borderRadius: '20px',
              padding: '8px 20px',
              marginLeft: '15px',
              textTransform: 'none',
              '&:hover': {
                background: 'linear-gradient(45deg, #5A4ECD 30%, #6A58EE 90%)',
              },
            }}
          >
            Book a Demo
          </Button>
          {user.isAuthenticated ? (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={(event) => setAnchorEl(event.currentTarget)} size="small">
                  <Avatar>{String(user.username)[0].toUpperCase()}</Avatar>
                </IconButton>
              </Box>
              <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
                <MenuItem>
                  <ListItemText primary={user.username} secondary={user.email} />
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => navigate('/user-profile')}>
                  <Link to='/user-profile' style={linkStyles}>Profile</Link>
                </MenuItem>
                <MenuItem onClick={handleLogoutButton}>
                  <Link to='/' style={linkStyles}>Logout</Link>
                </MenuItem>
              </Menu>
            </>
          ) : (
            <button onClick={handleLoginClick} style={{ backgroundColor: '#007bff', color: 'white', padding: '5px 10px', border: 'none', borderRadius: '5px', cursor: 'pointer', transition: 'background-color 0.3s' }}>Login</button>
          )}
        </nav>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
